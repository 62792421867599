<template>
  <div>
    <article class="tile is-child box detail-page tile">
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Customer</label>
        </div>
        <div class="field-body">
          <div class="field">
            <multiselect v-if="customers && !readOnlyView"
              :class="{ 'is-danger' : $v.entity.assetId.$error }"
              v-model="selectedCustomer"
              :disabled="!disableChange"
              :options="customers"
              deselect-label="Can't remove this value"
              label="assetName"
              placeholder="Select a customer"
              :max-height="250"
              :loading="isCustomerLoading"
              :internal-search="false"
              :options-limit="assetFilter.pageSize"
              @search-change="getAssetDropdownFilter"
              @select="selectAsset"
              @remove="removeAsset"
              :searchable="true"
              :show-labels="false"
              :allow-empty="true">
              <span class="has-text-danger"
                slot="noResult">Customer not found.</span>
            </multiselect>
            <p v-else-if="readOnlyView && selectedCustomer">{{ selectedCustomer.assetName }}</p>
            <span class="help is-danger"
              v-if="$v.entity && !$v.entity.assetId.validGuid">Customer is required.</span>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Date</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <v-date-picker v-model="selectedInvoiceDate"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Invoice Date"
                        :disabled="!disableChange"
                        :value="inputValue"
                        v-on="inputEvents"
                        readonly>
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal"
        v-show="showAmount">
        <div class="field-label is-normal">
          <label class="label has-text-left">Amount</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <vue-numeric class="input has-text-right"
                v-model="innerValue.amountPaid"
                :disabled="!disableChange"
                @blur="calculateTotalPaid()"
                type="text"
                placeholder=""
                :precision="2" />
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal"
        v-show="showAmount">
        <div class="field-label is-normal">
          <label class="label has-text-left">Previous Advance</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <vue-numeric class="input no-border has-text-right"
                v-model="innerValue.balance"
                :disabled="true"
                type="text"
                symbol="$"
                placeholder=""
                :precision="2" />
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal"
        v-show="showTotal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Total</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <vue-numeric class="input no-border has-text-right has-text-success"
                v-model="totalPaid"
                :disabled="true"
                type="text"
                symbol="$"
                placeholder=""
                :precision="2" />
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Cheque No.</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <p class="control">
              <input class="input is-capitalized"
                v-model="innerValue.chequeNo"
                :disabled="!disableChange"
                type="text"
                placeholder="">
            </p>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Bank Account</label>
        </div>
        <div class="field-body">
          <div class="field-body">
            <div class="field">
              <multiselect v-if="glBankAccountListCombo"
                v-model="innerValue.selectedBankAccount"
                :options="glBankAccountListCombo"
                :loading="isGlBankAccountLoading"
                :disabled="!disableChange"
                open-direction="above"
                placeholder="Select account"
                label="displayName"
                deselect-label="Can't remove this value"
                track-by="accountNo"
                :allow-empty="false">
                <span class="has-text-danger"
                  slot="noResult">Bank account not found.</span>
              </multiselect>
            </div>
          </div>
        </div>
      </div>

    </article>
  </div>
</template>

<script>
import Guid from '@/components/Guid'
//import _debounce from 'lodash.debounce'
import Multiselect from 'vue-multiselect'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import _debounce from 'lodash.debounce'
import { FocusInserted } from '@/components/directives'
import VueNumeric from '@/components/VueNumeric'
import _cloneDeep from 'lodash/cloneDeep'
import StoreMixin from './storeMixin'
import FactorInvoiceService from '../factorinvoice/FactorInvoiceService'
import QuickInvoiceService from '../quickinvoice/QuickInvoiceService'
import AdvanceReceiptService from './AdvanceReceiptService'

export default {
  name: 'AdvanceReceiptDetail',
  inject: ['$vv'],
  directives: {
    FocusInserted
  },
  components: {
    Multiselect,
    VueNumeric
  },
  mixins: [DateTimeFiltersMixin, StoreMixin],
  props: {
    value: null,
    disableChange: {
      type: Boolean,
      default: true
    },
    showAmount: {
      type: Boolean,
      default: true
    },
    showTotal: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      customers: [],
      bankAccounts: [],
      advInvoice: null,
      selectedInvoiceDate: null,
      selectedCustomer: null,
      totalPaid: null,
      glBankAccountListCombo: null,
      isGlBankAccountLoading: false,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      assetFilter: {
        assetId: Guid.empty(),
        assetName: '',
        showCustomer: true,
        showInsurer: false,
        pageIndex: 1,
        pageSize: 50
      },
      isCustomerLoading: false
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    selectedInvoiceDate(newVal, oldVal) {
      if (newVal) {
        this.innerValue.payDate = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.innerValue.payDate = null
      }
    }
  },
  async created() {
    this.GetGLBankAccountsDropdown()
    this.selectedInvoiceDate = new Date(`${this.innerValue.payDate}`)
    this.assetFilter.assetId = this.innerValue.assetId
    this.totalPaid = this.innerValue.amountPaid + this.innerValue.balance
    this.getAssetDropdown()
  },
  mounted() {
    this.$v.detailGroup.$touch()
  },
  methods: {
    async GetGLBankAccountsDropdown() {
      this.isGlBankAccountLoading = true
      this.glBankAccountListCombo = await FactorInvoiceService.getGLBankAccountsDropdownProto(this.$userInfo.companyId)
      this.isGlBankAccountLoading = false
    },
    async getAssetDropdown() {
      this.isAssetLoading = true
      this.customers = await QuickInvoiceService.getAssetDropdownProto(this.assetFilter)
      if (this.assetFilter.assetId !== Guid.empty()) {
        this.selectedCustomer = this.customers.find(c => c.assetId === this.innerValue.assetId)
      }
      this.isAssetLoading = false
    },
    calculateTotalPaid() {
      this.totalPaid = this.innerValue.amountPaid + this.innerValue.balance
    },
    getAssetDropdownFilter: _debounce(async function(query) {
      this.isAssetLoading = true
      this.assetFilter.assetName = query
      this.customers = await QuickInvoiceService.getAssetDropdownProto(this.assetFilter)
      this.isAssetLoading = false
    }, 500),
    async selectAsset(selected) {
      this.innerValue.assetId = selected.assetId
      this.innerValue.customerId = selected.assetId
      this.advInvoice = await AdvanceReceiptService.getTotalAdvPaymentAmount(selected.assetId)
      //console.log(Object.keys(this.advInvoice))
      //console.log('type of key: ' + typeof(Object.keys(this.advInvoice)))
      this.innerValue.invoiceId = this.advInvoice !== null ? Object.keys(this.advInvoice)[0] : Guid.empty()
      this.innerValue.balance = this.advInvoice !== null ? Number(Object.values(this.advInvoice)) : 0
      this.totalPaid = this.innerValue.balance + this.innerValue.amountPaid
    },
    removeAsset(removed) {
      this.assetFilter.assetId = Guid.empty()
      this.innerValue.assetId = Guid.empty()
      this.innerValue.balance = 0
      this.selectedCustomer = null
      this.totalPaid = this.innerValue.balance + this.innerValue.amountPaid
      this.getAssetDropdown()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';

input.is-uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
input.no-border {
  background-color: #eff5fb;
  border: none;
}
</style>
